/*-----------------------------------------------------------------------------
 Description: This file contains the function that makes the API request to
 the backend. It is called exclusively from chatApp/Component.jsx

 Notes:
  - The backend API is an AWS API Gateway endpoint that is configured to
    call an AWS Lambda function. The Lambda function is written in Python
    and calls the OpenAI API.

  - The backend API is configured to require an API key. The API key is
    passed in the header of the request. In real terms, the api key is
    pointless because it is exposed in the client code. However, it is
    required by the API Gateway configuration.

  - The backend API is configured to allow CORS requests from the client.
    This is necessary because the client and backend are served from
    different domains.

 Returns: the backend API is configured to return a JSON object that substantially
    conforms to the following structure for all 200 responses:
    v0.1.0 - v0.4.0:  ./test/events/openai.response.v0.4.0.json
    v0.5.0:       ./test/events/langchain.response.v0.5.0.json
-----------------------------------------------------------------------------*/

function mapResponse(response) {
  /*
    note: to maintain backward compatibility with the original OpenAI API response format.
    - OpenAI API responses are JSON objects of the format ./test/events/openai.response.v0.4.0.json
    - LangChain API responses are text/plain of the format ./test/events/langchain.response.v0.5.0.json
  */
  if (typeof response === "string") {
    try {
      response = JSON.parse(response);
    } catch (e) {
      console.error("Failed to parse response as JSON", e);
      return;
    }
  }
  if (
    response &&
    response["request_meta_data"] &&
    response["request_meta_data"]["lambda"] == "lambda_langchain"
  ) {
    const messages = response["chat_memory"]["messages"];
    let aiMessages = messages.filter((message) => message.type === "ai");
    let ai_response = "";

    if (aiMessages.length > 0) {
      ai_response = aiMessages[aiMessages.length - 1];
    }

    return {
      choices: [
        {
          index: 0,
          message: {
            role: "assistant",
            content: ai_response.content,
          },
          finish_reason: "stop",
        },
      ],
      request_meta_data: response["request_meta_data"],
    };
  }

  // legacy OpenAI response format
  return response;
}

export async function processApiRequest(
  chatMessage,
  chatHistory,
  apiURL,
  apiKey,
  openChatModal,
) {
  const init = {
    method: "POST",
    mode: "cors",
    headers: {
      "x-api-key": apiKey,
      Accept: "*/*",
      "Content-Type": "application/json",
      Origin: window.location.origin,
    },
    body: JSON.stringify({
      input_text: chatMessage,
      chat_history: chatHistory,
    }),
  };
  try {
    const response = await fetch(apiURL, init);
    const status = await response.status;
    const response_json = await response.json(); // Convert the ReadableStream to a JSON object
    const response_body = await response_json.body; // ditto

    if (response.ok) {
      return mapResponse(response_body);
    } else {
      /*
        note:
        - the response_body object is not available when the status is 504, because
          these responses are generated exclusively by API Gateway.
        - the response_body object is potentially not available when the status is 500
          depending on whether the 500 response was generated by the Lambda or the API Gateway
        - the response_body object is intended to always be available when the status is 400.
          However, there potentially COULD be a case where the response itself contains message text.
      */
      let errTitle = "Error " + status;
      let errMessage = "An unknown error occurred.";
      switch (status) {
        case 400:
          errMessage =
            response.statusText ||
            response_body.message ||
            "The request was invalid.";
          break;
        case 500:
          errMessage =
            response.statusText ||
            response_body.message ||
            "An internal server error occurred.";
          break;
        case 504:
          errMessage =
            response.statusText ||
            "Gateway timeout error. This is a known consequence of using AWS Lambda for integrations to the OpenAI API. Note that AWS Lambda has a hard 29 second timeout. If OpenAI requests take longer, which is frequently the case with chatgpt-4 then you will receive this error. If the timeout persists then you might try using chatgpt-3.5 instead as it is more performant.";
          break;
      }
      openChatModal(errTitle, errMessage);
    }
  } catch (error) {
    openChatModal("Error", error || "An unknown error occurred.");
    return;
  }
}
